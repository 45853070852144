import { Middleware } from '@nuxt/types';
import { CookieKeys } from '~/compositions/useCookie';
import useAppRoute from '~/compositions/useAppRoute';

/**
 * 認証確認用のミドルウェア
 *
 * ハードコードされている権限が必要なURLへアクセスが来た場合、
 * 認証済みでなければ認証画面へリダイレクトさせる
 * @param context
 */
const middleware: Middleware = (context) => {
  const { URLs } = useAppRoute();

  // ルーターパスによるこのミドルウェアの適用：非適用の判定を簡単にするため、
  // nuxt-i18n多言語化ライブラリがURLのプレフィクスとしてつける
  // 「/en/」 のようなロケール文字列がURLに含まれている場合は取り除く
  const origin_path = context.route.path.replace(/^\/[a-zA-Z]{2}\//, '/');

  // console.warn('⭐⭐⭐⭐ Middleware::authenticated.ts ⭐⭐⭐');
  // console.warn('origin_path', origin_path);

  // 認証が必要なURLにアクセスしてきているかどうか判定し、
  // 認証されていない場合は、認証画面にリダイレクトする
  switch (origin_path) {
    case URLs.show_settings:
    case URLs.edit_desired_conditions:
    case URLs.edit_activity_status:
    case URLs.edit_email:
    case URLs.edit_password:
    case URLs.show_messages:
    case URLs.show_message_with_id(Number(context.route.params.id)):
    case URLs.show_request_diagnosis_with_id(context.route.params.id):
    case '/' + URLs.offers: // ← TODO: ログインユーザのロールが学校か生徒か？でオファー画面のパスが異なるためマルチデバイス対応リリース時に追加した
    case URLs.show_offers:
    case URLs.show_offer_with_id(Number(context.route.params.id)):
    case URLs.show_notice_boards:
    case URLs.show_notice_board_with_id(Number(context.route.params.id)):
    case URLs.show_bookmarks:
    case URLs.show_profile:
    case URLs.edit_profile_base:
    case URLs.edit_profile_pr:
    case URLs.show_activities:
    case URLs.show_activity_with_id(Number(context.route.params.id)):
    case URLs.show_notifications:
    case URLs.show_notification_with_id(Number(context.route.params.id)):
    case URLs.show_entry_job_with_id(Number(context.route.params.id)):
    case URLs.show_entry_internship_with_id(Number(context.route.params.id)):
    case URLs.show_entry_seminar_with_id(Number(context.route.params.id)):
    case URLs.show_entry_company_visit_with_id(Number(context.route.params.id)):
      // cookieの中に【証明書】が存在するかどうか判定
      if (!context.app.$cookie.get(CookieKeys.CREDENTIALS)) {
        // 認証画面へリダイレクト
        // (※元々表示したかったURLの情報を渡す)
        context.redirect(context.app.localePath(URLs.show_sign_in), {
          _target: context.route.fullPath,
        });
      }
      // console.log('認証を掛ける必要の有るページなのでチェック');
      break;
    default:
      // console.log('認証を掛ける必要の無いページなのでスルー');
      break;
  }
};
export default middleware;
