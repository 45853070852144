import Vue from 'vue';

/**
 * 開発用、確認用の機能を提供するカスタムディレクティブ
 */
Vue.directive('dev', function (el, binding, vnode) {
  // 開発モードの取得
  const devMode = vnode.context?.$cookies?.get('dev-mode') as string;

  // 指定の開発モードを取得
  const mode = binding.value;

  // 有効な開発モードを判定
  const isDevMode = !!devMode && (!mode || mode === 'all' || mode === devMode);

  const action = binding.arg || 'show';
  switch (action) {
    case 'show':
      if (!isDevMode) el.style.display = 'none';
      break;
    default:
  }
});
