import { Context } from '@nuxt/types';
import {
  provide,
  onGlobalSetup,
  defineNuxtPlugin,
  onMounted,
  inject,
} from '@nuxtjs/composition-api';

import gql from 'graphql-tag';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import appStore, { AppStoreKey } from '~/store/app';
import { useConfig } from '~/compositions/useUtils';
import { APIHost } from '~/plugins/global-setup/provide_enviroment_hosts';
export default defineNuxtPlugin(({ app }: Context): void => {
  onGlobalSetup(() => {
    // console.warn('🌸🌸🌸 global-store.ts 🌸🌸🌸');
    const host = inject(APIHost);
    // console.warn('inject(APIHost)', host);
    const store = appStore();

    provide(AppStoreKey, store);

    onMounted(() => {
      // 初期表示でCredentialsがある場合はログイン履歴を残す
      if (store.isSignedIn && store.credentials) {
        const credentials = store.credentials;
        const client = new ApolloClient({
          ssrMode: true,
          cache: new InMemoryCache(),
          link: createHttpLink({
            uri: `${host}/graphql`,
            credentials: 'include', // withCredentials = true 設定
            headers: {
              client: credentials.client,
              uid: credentials.uid,
              expiry: credentials.expiry,
              'access-token': credentials.accessToken,
              'token-type': 'Bearer',
            },
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'cache-and-network',
            },
          },
        });

        // ユーザ情報を取得する
        client.query({
          query: gql`
            query {
              user(withLogin: true) {
                id
                email
                profile {
                  firstName
                  lastName
                }
              }
            }
          `,
        });
      }
    });
  });
});
