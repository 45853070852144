import { render, staticRenderFns } from "./error.vue?vue&type=template&id=56f9ea56&scoped=true&lang=pug&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"
import style0 from "./error.vue?vue&type=style&index=0&id=56f9ea56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f9ea56",
  null
  
)

/* custom blocks */
import block0 from "./error.vue?vue&type=custom&index=0&blockType=i18n&locale=en&lang=yml"
if (typeof block0 === 'function') block0(component)
import block1 from "./error.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ErrorContent: require('/app/src/components/organisms/ErrorContent.vue').default})
