import { render, staticRenderFns } from "./ErrorContent.vue?vue&type=template&id=1b83bbe4&scoped=true&lang=pug&"
import script from "./ErrorContent.vue?vue&type=script&lang=ts&"
export * from "./ErrorContent.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorContent.vue?vue&type=style&index=0&id=1b83bbe4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b83bbe4",
  null
  
)

/* custom blocks */
import block0 from "./ErrorContent.vue?vue&type=custom&index=0&blockType=i18n&locale=en&lang=yml"
if (typeof block0 === 'function') block0(component)
import block1 from "./ErrorContent.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadImage: require('/app/src/components/atoms/LoadImage.vue').default,DefaultButton: require('/app/src/components/atoms/DefaultButton.vue').default})
