import { Plugin } from '@nuxt/types';
import { UA } from 'nuxt-user-agent/lib/plugin.template';

// declare module '@nuxtjs/composition-api/dist/component/component' {
//   interface SetupContext {
//     readonly ua?: UA | false;
//   }
// }

// declare module 'vue/types/options' {
//   interface ComponentOptions<V extends Vue> {
//     // nuxtI18n?: NuxtI18nComponentOptions | false;
//     ua?: UA | false;
//   }
// }

// declare module '@nuxt/types' {
//   interface NuxtOptions {
//     // i18n?: NuxtVueI18n.Options.AllOptionsInterface;
//     ua?: UA | false;
//   }
// }

// 以下、いろいろな場所に型を生やす
declare module 'vue/types/vue' {
  interface Vue {
    $ua?: UA;
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $ua?: UA;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $ua?: UA;
  }
}

/**
 * Nuxtにシステム共通のエラー処理が出来る関数を用意するプラグイン
 * 呼び出すと、Sentryに対してエラーログが送信されると共に、
 * Nuxtのフレームワークのエラー処理が実行される
 * 使用するときは、ComponentやPageの側で、
 * return context.root.$error({})といった形で呼び出す。
 * (※必ず return しながら使わないと、下の処理に流れてしまうので注意)
 */
const plugin: Plugin = (context, inject) => {
  // // 関数をその場で実体化しながらインジェクトする
  // inject('error', (nuxterror: NuxtError) => {
  //   // ログ蓄積のWebサービス(Sentry)にエラーを送信しておく
  //   context.$sentry.captureException(new Error(nuxterror.message));
  //   // Nuxtフレワームワークの共通エラー処理を呼んでおく
  //   context.error(nuxterror);
  // });
};
export default plugin;

// declare module 'vuex/types/index' {
//   interface Store<S> {
//     readonly $i18n: VueI18n & IVueI18n;
//   }
// }
