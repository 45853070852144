import { Middleware } from '@nuxt/types';
import useAppRoute from '~/compositions/useAppRoute';

/**
 * 認証情報を持った状態でサイトにアクセスしてきた場合で、
 * 認証情報の中に【リダイレクトトークン】が存在する場合
 * (※つまり、学校ユーザか企業ユーザでログインしている場合)
 * は、学生用のサイトを見せるわけには行かないので、
 * どのページを見に来た場合でも、とりあえず認証用のページを再度表示してやる。
 *
 * @param context
 */
const middleware: Middleware = (context) => {
  const { URLs } = useAppRoute();

  // ログインページかどうか判定するための正規表現オブジェクト生成
  const loginRegex = new RegExp('^' + URLs.show_sign_in + '.*$');
  const logoutRegex = new RegExp('^' + URLs.show_sign_out + '.*$');

  // 正規表現でログインページか、ログアウトページかどうか判定
  if (loginRegex.test(context.route.path) || logoutRegex.test(context.route.path)) {
    // ログインまたはログアウトページだったので何もしない
    return;
  }

  // 認証情報を取得する
  const credentials = context.$cookies.get('credentials', {
    fromRes: true,
    parseJSON: true,
  });

  // 認証情報と、その中にリダイレクトトークンが含まれている場合はNuxtの認証ページを再表示してやる
  if (credentials?.redirectToken) {
    // 認証トークンがあった場合はNuxtの認証ページに飛ばしてやる。
    //context.route.query.locking = 'true'
    //return context.redirect(URLs.show_sign_in, context.route.query);

    // 認証トークンがあった場合はNuxtのログアウトページに飛ばしてやる。
    // ログアウトからログインページに戻ってくるため、問題ない。
    return context.redirect(URLs.show_sign_out, context.route.query);
  }
};
export default middleware;
