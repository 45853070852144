import { defineNuxtPlugin } from '@nuxtjs/composition-api';

import Vue from 'vue';
import { IntersectDirective } from 'vue-intersect-directive';

Vue.directive('intersect', IntersectDirective);
// export interface IntersectOptions;
// export interface IntersectOptions

/**
 * JSON.stringfy()の挙動を変化させたい場合は、
 * このプラグインで記述していく
 */
// export default defineNuxtPlugin((context, _inject) => {});
