import Vue from 'vue';

/**
 * v-testid="" というカスタム属性を追加
 * testid=""で文字列を渡すことができる。
 * production(本番)ではないときだけテストIDを表示
 */
Vue.directive('testid', function (el, binding, vnode) {
  // 本番でテストIDを確認したい時は、.envのTEST_MODEを'1'に変更。
  const testMode = vnode.context?.$config?.TEST_MODE || '0';
  const forceTest = testMode === 1;
  // console.log('testMode', testMode);
  // console.log(binding.value);

  if (forceTest || process.env.NODE_ENV !== 'production') {
    el.dataset.testid = binding.value;
  }
});
