import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faHome as freeFasFaHome,
  faHeart as freeFasFaHeart,
  faBookmark as freeFasFaBookmark,
  faCog as freeFasFaCog,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight,
  faBell as freeFasFaBell,
  faThumbtack as freeFasFaThumbtack,
  faPencilAlt as freeFasFaPencilAlt,
  faCaretDown as freeFasFaCaretDown,
  faPlus as freeFasFaPlus,
  faSyncAlt as freeFasFaSyncAlt,
  faArrowsAltH as freeFasFaArrowsAltH,
  faPaperPlane as freeFasFaPaperPlane,
  faDownload as freeFasFaDownload,
  faEye as freeFasFaEye,
  faStar as freeFasFaStar,
  faCheckCircle as freeFasFaCheckCircle,
  faUserCircle as freeFasFaUserCircle
} from '@fortawesome/free-solid-svg-icons'

import {
  faHeart as freeFarFaHeart,
  faBookmark as freeFarFaBookmark,
  faBell as freeFarFaBell,
  faComment as freeFarFaComment,
  faQuestionCircle as freeFarFaQuestionCircle
} from '@fortawesome/free-regular-svg-icons'

library.add(
  freeFasFaHome,
  freeFasFaHeart,
  freeFasFaBookmark,
  freeFasFaCog,
  freeFasFaChevronLeft,
  freeFasFaChevronRight,
  freeFasFaBell,
  freeFasFaThumbtack,
  freeFasFaPencilAlt,
  freeFasFaCaretDown,
  freeFasFaPlus,
  freeFasFaSyncAlt,
  freeFasFaArrowsAltH,
  freeFasFaPaperPlane,
  freeFasFaDownload,
  freeFasFaEye,
  freeFasFaStar,
  freeFasFaCheckCircle,
  freeFasFaUserCircle,
  freeFarFaHeart,
  freeFarFaBookmark,
  freeFarFaBell,
  freeFarFaComment,
  freeFarFaQuestionCircle
)

config.autoAddCss = false

Vue.component('faIcon', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
