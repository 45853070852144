import { onGlobalSetup, provide, InjectionKey } from '@nuxtjs/composition-api';
import { Plugin } from '@nuxt/types';
import { useConfig } from '~/compositions/useUtils';

export const APIHost: InjectionKey<String> = Symbol('APIHost');
export const RailsHost: InjectionKey<String> = Symbol('RailsHost');

/**
 * システム全域に渡ってグローバルスコープでRailsが動いているホスト文字列を使えるようにするためのプラグイン
 */
const ApolloPlugin: Plugin = ({ app }) => {
  onGlobalSetup(() => {
    const config = useConfig();

    // 学校デモ環境かどうかをドメイン文字列で判定
    // (※xxx.cmap-demo.comならデモ環境と見做す)
    // そうでなければ、他の環境だとみなして、環境変数のAPI_HOSTをベースURIとする
    const get_api_base = () => {
      const pattern = /^([-a-z0-9]+)(\.cmap-demo\.com)$/;
      const host = process.server ? app.ssrContext.event.node.req.headers.host : window.location.host;
      const uri_base = (pattern.test(host)) ? host.replace(pattern, 'https://$1-api$2') : `${config.API_HOST || process.env.API_HOST}`;
      return uri_base;
    };

    // 学校デモ環境かどうかをドメイン文字列で判定
    // (※xxx.cmap-demo.comならデモ環境と見做す)
    // そうでなければ、他の環境だとみなして、環境変数のRAILS_HOSTをベースURIとする
    const get_rails_base = () => {
      const pattern = /^([-a-z0-9]+)(\.cmap-demo\.com)$/;
      const host = process.server ? app.ssrContext.event.node.req.headers.host : window.location.host;
      const uri_base = pattern.test(host) ? host.replace(pattern, 'https://$1-rails$2') : `${config.ADMIN_HOST || process.env.ADMIN_HOST}`;
      return uri_base;
    };

    
    // @ts-ignore
    provide(APIHost, get_api_base());
    provide(RailsHost, get_rails_base());
  });
};
export default ApolloPlugin;
