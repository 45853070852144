import { NuxtError, Plugin } from '@nuxt/types';

/**
 * NuxtError型を引数とするラッパー関数の型定義
 */
type Error = (nuxterror: NuxtError) => void;

// 以下、いろいろな場所に型を生やす
declare module 'vue/types/vue' {
  interface Vue {
    $error: Error;
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $error: Error;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $error: Error;
  }
}

/**
 * Nuxtにシステム共通のエラー処理が出来る関数を用意するプラグイン
 * 呼び出すと、Sentryに対してエラーログが送信されると共に、
 * Nuxtのフレームワークのエラー処理が実行される
 * 使用するときは、ComponentやPageの側で、
 * return context.root.$error({})といった形で呼び出す。
 * (※必ず return しながら使わないと、下の処理に流れてしまうので注意)
 */
const plugin: Plugin = (context, inject) => {
  // 関数をその場で実体化しながらインジェクトする
  inject('error', (nuxterror: NuxtError) => {
    // ログ蓄積のWebサービス(Sentry)にエラーを送信しておく
    context.$sentry.captureException(new Error(nuxterror.message));

    // Nuxtフレワームワークの共通エラー処理を呼んでおく
    context.error(nuxterror);
  });
};
export default plugin;
