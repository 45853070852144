import { MutationTree, ActionTree } from 'vuex';

// ステートの型定義
export interface State {
  notificationOfReadings: number[];
}

// ステートの初期状態を定義
export const state: () => State = () => ({
  notificationOfReadings: [],
});

// ミューテーションの定義
export const mutations: MutationTree<State> = {
  notificationOfReadingsMutation(state, payload: number) {
    state.notificationOfReadings.push(payload);
  },
};

// アクションの定義
export const actions: ActionTree<State, any> = {
  // アクションで引数を受け取り、ミューテーションに渡す
  updateNotificationOfReadings({ commit }, notificationId: number) {
    commit('notificationOfReadingsMutation', notificationId);
  },
};
