import { MutationTree, ActionTree } from 'vuex';
import useLoadingText from '@/compositions/useLoadingText';

const { loadingTextList } = useLoadingText();
export interface State {
  iconRandNum: number | null;
  textRandNum: number | null;
}

export const state: () => State = () => ({
  iconRandNum: null,
  textRandNum: null,
});

export const mutations: MutationTree<State> = {
  iconRandNumMutation(state) {
    state.iconRandNum = Math.floor(Math.random() * 2);
  },
  iconRandNumClearMutation(state) {
    state.iconRandNum = null;
  },
  textRandNumMutation(state) {
    state.textRandNum = Math.floor(Math.random() * loadingTextList.length);
  },
  textRandNumClearMutation(state) {
    state.textRandNum = null;
  },
};

export const actions: ActionTree<State, any> = {
  updateIconRandNum({ commit }) {
    commit('iconRandNumMutation');
  },
  clearIconRandNum({ commit }) {
    commit('iconRandNumClearMutation');
  },
  updateTextRandNum({ commit }) {
    commit('textRandNumMutation');
  },
  clearTextRandNum({ commit }) {
    commit('textRandNumClearMutation');
  },
};
