import { Middleware } from '@nuxt/types';
// import { useAppStore } from '@/store/app';
// import { computed, reactive } from '@nuxtjs/composition-api';
// import { useSignInUserQuery, User } from '@/graphql/graphqlOperations';
// import { UseQueryOptions } from '@vue/apollo-composable/dist';
import gql from 'graphql-tag';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { User } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import fetch from 'cross-fetch';
import { CookieKeys } from '~/compositions/useCookie';
import { useUuid } from '~/compositions/useUuid';
// import useAppRoute from '~/compositions/useAppRoute';
/**
 * Sentry(Webロギングサービス)に、
 * システムを利用中のユーザの情報を設定するミドルウェア
 *
 * TODO: ルーター遷移する度に呼び出されるので、タイミングとしては頻度が多すぎかも知れない。
 *       ログインページの認証処理後に一回だけ処理を行うほうが動作コストが少ないので、
 *       本当にnuxt.config.tsの共通middlrewareでこの処理を実行すべきなのかどうか要検討
 * @param context
 */
const middleware: Middleware = async (context) => {
  // console.log('TODO:ここでSentryにsetUser()する');

  // ユーザ情報定義
  const user: User = {
    uuid: null,
  };

  // ユーザ認証後の【証明書】を Cookie から取り出す
  const credentials = context.app.$cookie.get(CookieKeys.CREDENTIALS);

  // 証明書が取得出来た場合だけ処理を開始する
  if (credentials) {
    // console.log('認証済みだったのでユーザ取得にはしる');
    // console.log(`client: ${credentials.client}`);
    // console.log(`uid: ${credentials.uid}`);
    // console.log(`access-token: ${credentials.accessToken}`);
    // console.log(context.app);
    // console.log(context.app.apolloProvider?.clients.defaultClient);
    // console.log(context.app.apolloProvider?.defaultClient.link);

    // TODO: ここで、Sentryの初期化を行って、ユーザを特定するために【id】や【email】の情報を設定しておく

    // TODO: そういうわけでユーザ情報を取得するために本当はこうしたい ↓↓↓ が
    //       useAppStore()内部で inject() があるので composition-api の機構とは
    //       無関係である nuxt の middleware では ライフサイクルのスコープ外で inject() メソッドが呼び出せない
    //       (※基本的には setup 関数内部でしか呼び出せない)
    //
    // const { store, reloadUser } = useAppStore();
    // console.log(store);
    // console.log(reloadUser);

    // TODO: 仕方がないのでApolloClientインスタンスをここで作ってユーザ情報を取りに行くという暴挙を行っているが
    //       どう考えても仕組み上良いわけないので他の方法に置き換える必要があるやろな・・・
    const client = new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache(),
      link: createHttpLink({
        // 学校デモ環境かどうかをドメイン文字列で判定
        // (※xxx.cmap-demo.comならデモ環境と見做す)
        // そうでなければ、他の環境だとみなして、環境変数のAPI_HOSTをベースURIとする
        uri: (() => {
          const pattern = /^([-a-z0-9]+)(\.cmap-demo\.com)$/;
          const host = window.location.host;
          const uri_base = pattern.test(host)
            ? host.replace(pattern, 'https://$1-api$2')
            : `${context.$config.API_HOST || process.env.API_HOST}`;
          return `${uri_base}/graphql`;
        })(),
        credentials: 'include', // withCredentials = true 設定
        headers: {
          client: credentials.client,
          uid: credentials.uid,
          expiry: credentials.expiry,
          'access-token': credentials.accessToken,
          'token-type': 'Bearer',
        },
        fetch,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });

    // ユーザ情報を取得する
    const response = await client.query({
      query: gql`
        query {
          user {
            id
            email
            profile {
              firstName
              lastName
            }
          }
        }
      `,
    });

    // ユーザ情報変数にそれぞれのデータを格納
    // TODO: 何かしらないが creadential が存在するにも関わらず response の中の user の中身が null のときがある
    //       意味がわからないが、一旦落ちない様に Optional Chaining で逃げておくが、要調査
    user.id = response?.data?.user?.id;
    user.email = response?.data?.user?.email;
    user.username = `${response?.data?.user?.profile?.firstName} ${response?.data?.user?.profile?.lastName}`;
  }

  // UUIDを取得（作成）する
  const { uuid: user_uuid } = useUuid({ context });

  // ユーザ情報変数にUUIDを格納
  user.uuid = user_uuid;

  // console.log('ユーザの中身出してみるやで！');
  // console.log(user);
  // ユーザ情報を設定する
  // scope.setUser(user);
  // });

  // 実際にSentryのユーザとして設定する
  Sentry.setUser(user);
};
export default middleware;
