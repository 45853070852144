import { Middleware } from '@nuxt/types';
// import { useAppStore } from '@/store/app';
// import { computed, reactive } from '@nuxtjs/composition-api';
// import { useSignInUserQuery, User } from '@/graphql/graphqlOperations';
// import { UseQueryOptions } from '@vue/apollo-composable/dist';
import gql from 'graphql-tag';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { User } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import uuid from 'uuid-random';
import { CookieKeys } from '~/compositions/useCookie';
// import useAppRoute from '~/compositions/useAppRoute';

/**
 * @param context
 */
const middleware: Middleware = (context) => {
  // console.log('TODO:ここでSentryにsetUser()する');
  // console.warn(context.app.apolloProvider);
  // console.log(context.app.apolloProvider?.clients);
  // console.log(context.app.apolloProvider?.defaultClient);
};
export default middleware;
