import {
  InjectionKey,
  reactive,
  inject,
  getCurrentInstance,
  ref,
} from '@nuxtjs/composition-api';
import {
  useSignInUserQuery,
  // useUserNoticeCountQuery,
  User,
} from '@/graphql/graphqlOperations';
import { Credential } from '@/graphql/graphqlDeviseOperations';
import { UseQueryOptions } from '@vue/apollo-composable/dist';
import useCookie, { CookieKeys } from '~/compositions/useCookie';
import useAppRoute from '~/compositions/useAppRoute';

// 通知数管理
interface Notifications {
  total: number; // 合計数
  message: number; // メッセージ
  offer: number; // 新着オファー
  news: number; // 新着事務局お知らせ
  noticeBoard: number; // 新着学校掲示板
  // feed: number; // 新着注目記事
  // job: number; // 新着求人
}

// Storeで管理する項目
type AppStoreStates = {
  user?: User | null;
  credentials: Credential | null;
  notifications: Notifications;
  appEnv: 'web' | 'ios' | 'android' | 'flutter';
};

// Store項目の初期値
const defaultStates: AppStoreStates = {
  user: null,
  credentials: null,
  appEnv: 'web', // アプリ実行環境
  notifications: {
    total: 0,
    message: 0,
    offer: 0,
    news: 0,
    noticeBoard: 0,
    // feed: 0,
    // job: 0,
  },
};

export default function appStore() {
  const { setCookie, removeCookie, getCookie } = useCookie();
  const state = reactive<AppStoreStates>({
    ...defaultStates,
    credentials: getCookie(CookieKeys.CREDENTIALS),
    appEnv: getCookie(CookieKeys.APP_ENV) || 'web',
  });

  /**
   * 認証情報をCookieに保存
   */
  const saveCredentialsToCookie = (value: string) => {
    setCookie(CookieKeys.CREDENTIALS, value);
  };

  /**
   * 認証情報を削除
   */
  const removeCredentials = () => {
    removeCookie(CookieKeys.CREDENTIALS);
  };

  return {
    /**
     * ログインチェック
     */
    get isSignedIn() {
      return !!state.credentials;
    },

    /**
     * 認証情報を取得
     */
    get credentials() {
      if (!state.credentials) {
        state.credentials = getCookie(CookieKeys.CREDENTIALS);
      }
      return state.credentials;
    },

    /**
     * 認証情報を設定
     */
    setCredentials(credentials: Credential | null) {
      state.credentials = credentials;
      if (credentials) {
        saveCredentialsToCookie(JSON.stringify(credentials));
      } else {
        removeCredentials();
      }
    },

    /**
     * ログインユーザー情報を取得
     */
    get user() {
      return state.user;
    },

    /**
     * ログインユーザー情報を設定
     */
    setUser(user?: any) {
      state.user = user;
    },

    /**
     * 通知を取得
     */
    get notifications() {
      return state.notifications;
    },

    /**
     * 通知を設定
     */
    setNotifications(notifications: Notifications) {
      state.notifications = notifications;

      // アプリバッヂ用変数
      const badgeCount =
        notifications.news +
        notifications.message +
        notifications.offer +
        notifications.noticeBoard;
      setCookie(CookieKeys.NotificationCount, badgeCount);
    },

    /**
     * iOSアプリ判定
     */
    get isIOS() {
      return state.appEnv === 'ios';
    },

    
    /**
     * Androidアプリ判定
     */
    get isAndroid() {
      return state.appEnv === 'android';
    },
    
    /**
     * Flutter判定(バージョンアップしていない場合があるため、既存のコードを置いておかなければならない)
     */
    get isFlutter() {
      return state.appEnv === 'flutter';
    },
    
    /**
     * ログアウト
     */
    signOut() {
      state.credentials = null;
      state.user = null;
      removeCredentials();
      removeCookie(CookieKeys.NotificationCount);
    },
  };
}

export type AppStore = ReturnType<typeof appStore>;

export const AppStoreKey: InjectionKey<AppStore> = Symbol('AppStore');

export function useAppStore() {
  const { URLs } = useAppRoute();
  const store = inject(AppStoreKey);

  if (!store) {
    throw new Error(`${AppStoreKey.toString()} is not provided`);
  }

  const reloadUser = ref(() => {
    // console.log('nothing to do');
  });

  const getNoticeCount = ref(() => {
    // console.log('nothing to do');
  });

  const vm = getCurrentInstance()?.proxy;
  if (!vm?.$isServer) {
    const signInUserOptions = reactive({
      enabled: false,
    });
    const { onResult, onError, refetch } =
      useSignInUserQuery(signInUserOptions);
    reloadUser.value = async () => {
      signInUserOptions.enabled = true;
      await refetch();
    };
    if (store.isSignedIn) {
      signInUserOptions.enabled = true;
    }
    onResult((param) => {
      if (param?.data) {
        if (param.data.user) {
          store.setUser(param.data.user);
        } else {
          store.signOut();
          vm?.$router.push(vm.localePath(URLs.show_sign_in));
        }
      }
    });
    onError((error) => {
      // console.log(error);
      throw error;
    });

    // const noticeCountOptions = reactive({
    //   enabled: false,
    // });
    // const { onResult: onNoticeCountResult, refetch: NoticeCountRefetch } =
    //   useUserNoticeCountQuery(noticeCountOptions);
    // getNoticeCount.value = () => {
    //   noticeCountOptions.enabled = true;
    // };

    // onNoticeCountResult((param) => {
    //   const data = param?.data?.userNoticeCount;
    //   if (data) {
    //     store.setNotifications({
    //       total: data.news + data.message + data.offer + data.noticeBoard,
    //       news: data.news,
    //       message: data.message,
    //       offer: data.offer,
    //       noticeBoard: data.noticeBoard,
    //     });
    //   }
    // });
  }

  return {
    store,
    reloadUser: reloadUser.value,
    getNoticeCount: getNoticeCount.value,
  };
}
