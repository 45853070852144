import { Middleware } from '@nuxt/types';
import useAppRoute from '~/compositions/useAppRoute';

/**
 * PCで見ている人を問答無用で(旧)キャリアマップに飛ばすミドルウェア
 *
 * @param context
 */
const middleware: Middleware = (context) => {
  const { URLs } = useAppRoute();

  const get_rails_base = () => {
    const pattern = /^([-a-z0-9]+)(\.cmap-demo\.com)$/;
    const host = window.location.host;
    const uri_base = pattern.test(host)
      ? host.replace(pattern, 'https://$1-rails$2')
      : `${context.$config.ADMIN_HOST || process.env.ADMIN_HOST}`;
    return uri_base;
  };

  // console.warn('⭐⭐⭐⭐ middleware ⭐⭐⭐⭐⭐');
  // console.warn(get_rails_base());
  
  // 開発モードの時は特に何もしない;
  if (context.$config.ENV === 'development') {
    return;
  }

  // IEは動作対象外なので、IEを見つけたら【推奨ブラウザ使え】というページに飛ばす
  if (context.app.$ua?.browser().match('Internet Explorer')) {
    // リダイレクトループを防ぐために【推奨ブラウザ使え】ページ以外のときだけ飛ばす
    if (URLs.recommended_browsers !== context.route.path) {
      return context.redirect(URLs.recommended_browsers);
    }
    return;
  }

  // // PCで見ている人はもうPCのサイトに飛ばす
  // if (context.app.$ua?.isFromPc()) {
  //   console.warn('PC判定だったよ');
  //   // location.href = `${get_rails_base()}${context.route.fullPath}`;
  //   return context.redirect(
  //     `${get_rails_base()}${context.route.fullPath}`
  //   );
  // }

  let pattern = null;
  let matcher = null;

  // 証明書発行用のURLが入力されたら(旧)サイトに飛ばす
  pattern = /^\/certificate_applications\/.*$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      `${get_rails_base()}${context.route.fullPath}`
    );
  }

  // 採用サイトのURLが入力されたら(旧)サイトに飛ばす
  pattern = /^\/recruit\/page_.+$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      `${get_rails_base()}${context.route.fullPath}`
    );
  }

  // 採用サイトのエントリーフォームのURLが入力されたら(旧)サイトに飛ばす
  pattern = /^\/extjobs\/.*$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      `${get_rails_base()}${context.route.fullPath}`
    );
  }

  // 学校経由の新規登録フォームのURLが入力されたら(旧)サイトに飛ばす
  pattern = /^\/(.+\/)?recruit_request\/.*$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      `${get_rails_base()}${context.route.fullPath}`
    );
  }

  // メッセージ又は、Eメールに貼り付けられたURLがこういうパターンの時
  // https://www.careermap.jp/messages/corporations/41435
  // これは、 /messages へ飛ばす
  pattern = /^\/messages\/corporations\/[0-9]+$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(URLs.show_messages);
  }

  // メッセージ又は、Eメールに貼り付けられたURLがこういうパターンの時
  //   https://www.careermap.jp/messages/group/98692                // ← TODO: これは 旧サイトに飛ばす今の状態でOK
  //   https://www.careermap.jp/messages/{ここはなんでもよい}/9999
  // は、学校アカウントか企業アカウントの人が、このURLを踏むはずなので、(旧)サイトに飛ばす
  pattern = /^\/messages\/[^/]+\/[0-9]+$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      `${get_rails_base()}${context.route.fullPath}`
    );
  }

  // 旧Railsで企業ページの下にぶら下がる形で募集情報がくっついてるURLが来た場合にバイパスする
  // https://www.careermap.jp/jobs/show_corp/28543/position/detail/117233
  // ↓これにすればOK
  // https://www.careermap.jp/jobs/117233 （編集済み）
  pattern = /^\/jobs\/show_corp\/[0-9]+\/position\/detail\/([0-9]+)$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      URLs.show_recruit_info_with_id(Number(matcher?.[1]))
    );
  }

  // 旧サイトの企業ページ系のURLは拾って新しいパスに飛ばす
  // https://www.careermap.jp/jobs/show_corp/2999
  // https://www.careermap.jp/jobs/show_corp/2999/message
  // https://www.careermap.jp/jobs/show_corp/{id}/{これ以降どんな文字が入ってきても}
  pattern = /^(\/jobs\/show_corp\/([0-9]+))(\/.*)?$/u;
  matcher = pattern.exec(context.route.fullPath);
  if (matcher) {
    return context.redirect(
      URLs.show_corporation_summary_with_id(Number(matcher?.[2]))
    );
  }

  // console.log(`01.${context.app.$ua?.isFromPc()}`);
  // console.log(`02.${context.app.$ua?.isFromSmartphone()}`);
  // console.log(`03.${context.app.$ua?.isFromMobilephone()}`);
  // console.log(`04.${context.app.$ua?.isFromTablet()}`);
  // console.log(`05.${context.app.$ua?.isFromAppliance()}`);
  // console.log(`06.${context.app.$ua?.isFromCrawler()}`);
  // console.log(`07.${context.app.$ua?.isFromIos()}`);
  // console.log(`08.${context.app.$ua?.isFromIpad()}`);
  // console.log(`09.${context.app.$ua?.isFromIphone()}`);
  // console.log(`10.${context.app.$ua?.isFromWindowsPhone()}`);
  // console.log(`11.${context.app.$ua?.os()}`);
  // console.log(`12.${context.app.$ua?.osVersion()}`);
};
export default middleware;
