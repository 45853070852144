import { Middleware } from '@nuxt/types';
import useAppRoute from '~/compositions/useAppRoute';

/**
 * 開発モード管理のミドルウェア
 *
 * @param context
 */
const middleware: Middleware = (context) => {
  const devMode = context.route.query['dev-mode'] as string;
  if (devMode) {
    if (devMode === 'clear') {
      context.app.$cookies.remove('dev-mode');
    } else {
      context.app.$cookies.set('dev-mode', devMode);
    }
  }
};
export default middleware;
