import { defineNuxtPlugin } from '@nuxtjs/composition-api';

/**
 * JSON.stringfy()の挙動を変化させたい場合は、
 * このプラグインで記述していく
 */
export default defineNuxtPlugin((context, _inject) => {
  /**
   * Date型のtoJSONをプロトタイプオーバーライドする。
   *
   * 現在GraphQLサバ側が、 2021-04-03T15:00:00Z の様な日時形式の文字列を受けつけはするが、
   * サバ側で正しくタイムゾーンが Asia/Tokyo として復元されないので、UTC表記がそのままJSTとして
   * 9時間ズレでデシリアライズされる問題が存在する。筋としてはサバ側が対応すべきとも言えるが、
   *
   *   2021-04-03T00:00:00+09:00
   *
   * といったタイムゾーン情報が分かる ISO8601拡張 形式の文字列もサバが受け付ける事が可能なので、
   * こちらの文字列をクライアントからGraphQLサーバに投げたい。
   *
   * しかし GraphQLクライアントである apollo-composable は、クエリの検索条件を、
   * JSON.stringfy(value) によってシリアライズしてGraphQLサーバに投げており、
   * JSON.stringfy(value,replacer)となっていないため、
   * 第二引数のJSON変換を拡張するポイントを利用できない。
   * そのため、方法としては、Date型をシリアライズするために、
   * JSON.stringfyの中で呼び出されている
   *
   *  「Date.toJSON」「Date.toISOString」
   *
   * のどちらかの挙動を変化させるか、
   * システム全域で【日時型】を「文字列」として最初から扱うかしか方法が存在しないが、
   * 現在システム全域で利用している buefy の timepicker や clockpicker は
   * Javascriptの Date型 をv-modelで結び付けられる前提のUIフレームワークなため、
   * 日付型を全て「文字列」とする戦略は取り難い。
   *
   * 結論、本来ならやりたくないが、仕方なしに、Date型の「toJSON」をオーバーライドして対応する。
   */
  Date.prototype.toJSON = function () {
    return context.$dayjs(this).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  };
});
