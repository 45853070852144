// import { useUserAgent } from '~/compositions/useUtils';
import { ref } from '@nuxtjs/composition-api';
import { FileInfo, ReviewStatus } from '~/graphql/graphqlOperations';
import { useAppStore } from '~/store/app';
declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}
window.flutter_inappwebview = window.flutter_inappwebview || {};

export default function useFlutter() {
  // const userAgent = useUserAgent();
  const { store } = useAppStore();
  const flutterInAppWebview = window.flutter_inappwebview;

  // webView表示の場合、Flutterからのアクセス
  const isFlutter: boolean = store.isFlutter;
  // const isFlutter: boolean = userAgent?.browser() === 'Webview';

  // ログイン情報を保存
  const saveLoginInfo = (info: Object) => {
    flutterInAppWebview.callHandler('saveLoginInfo', info);
  };

  // 履歴書ダウンロード
  const downloadResume = (resume: string) => {
    flutterInAppWebview.callHandler('downloadResume', resume);
  };

  // ファイルダウンロード
  const downloadFile = (file: FileInfo, protocol: string) => {
    // Flutter側でログイン情報保存する
    flutterInAppWebview.callHandler('downloadFile', file, protocol);
  };

  // ストアレビュー表示
  const showStoreReview = () => {
    flutterInAppWebview.callHandler('showStoreReview');
  };

  // デバイスのレビューステータスを変更
  const updateDeviceReviewStatus = (status: ReviewStatus) => {
    flutterInAppWebview.callHandler('updateReviewStatus', status);
  };

  // 注目記事閲覧回数を設定
  const setFeedViews = (type: String) => {
    flutterInAppWebview.callHandler('setFeedViews', type);
  };

  // バッヂ数を設定
  const setAppBadgeCount = async (badgeCount: number) => {
    return await flutterInAppWebview.callHandler('setBadgeCount', badgeCount);
  };

  // ソーシャルログイン
  const clickSocialSignIn = async (provider: string) => {
    return await flutterInAppWebview.callHandler('clickSocialSignIn', provider);
  };

  // 機能の有効・無効をチェック
  const isEnabledFeature = async (featureName: string) => {
    try {
      return await flutterInAppWebview.callHandler(
        'isEnabledFeature',
        featureName
      );
    } catch (e) {
      return false;
    }
  };

  // SSOの有効チェック
  const isEnabledSSO = ref<boolean>(!isFlutter);
  if (isFlutter) {
    isEnabledFeature('sso').then((result) => {
      isEnabledSSO.value = result;
    });
  }

  return {
    downloadResume,
    downloadFile,
    saveLoginInfo,
    showStoreReview,
    updateDeviceReviewStatus,
    setFeedViews,
    setAppBadgeCount,
    // ソーシャルログインボタン押下
    clickSocialSignIn,
    // SSOの有効チェック
    isEnabledSSO,
    flutterInAppWebview,
    isFlutter,
  };
}
