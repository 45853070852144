// 多言語情報をLazyにロードするためのtsファイル。
// 例えばブラウザの設定言語が ja になっていた場合、Accept-languageがjaを投げてくるので
// フォールバック言語になっている「en」と対応している言語「ja」の多言語ファイルだけをロードする。
// もしもアプリが対応していないAccept-languageが飛んできた場合は、フォールバック言語である「en」
// のファイルだけがロードされて表示される。
// 画面上の言語切替機能などによって、switch-localeが呼ばれた場合、
// 対象となる言語情報をそのタイミングでLazyにロードすることによって通信の無駄を省く
// (※いまは言語が存在するごとにen.ts等のファイルを作成し、nuxt.config.tsで指定記述しているが、
//   一つのプログラムファイルにまとめることが出来るかもしれない)
import { Context } from '@nuxt/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
export default (context: Context) => {
  // TODO: 動作はするが、ymlのパスのimportを解決できない警告が出るのでどこかのタイミングで解決しなければならない
  //       今は「@ts-ignore」で警告を潰しておく
  // @ts-ignore
  return import('@/assets/i18n/commons/ja.yml');
};
