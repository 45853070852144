import locale77830c60 from '../../src/lang/ja.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ja","silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"ja","iso":"ja-JP","file":"ja.ts"},{"code":"en","iso":"en-US","file":"en.ts"}],
  defaultLocale: "ja",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/app/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"ja","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true,"crossOriginCookie":false},
  differentDomains: false,
  seo: false,
  baseUrl: (_context) =>
    // TODO: とりあえずSSRの時はベタ書きしたドメインを返しておくが、
    //       コンテキストの中からリクエストヘッダを引きずり出してドメイン文字列を組み立て無いと行けない
    process.client ?
    `${window.location.protocol}//${window.location.host}` :
    'https://www.careermap.jp',
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: (_oldLocale, _newLocale) => {
      // console.log(`言語変更前【${oldLocale}】から【${newLocale}】へ`);
    },
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: (_oldLocale, _newLocale) => {
      // console.log(`言語変更後【${oldLocale}】から【${newLocale}】へ`);
    },
  normalizedLocales: [{"code":"ja","iso":"ja-JP","file":"ja.ts"},{"code":"en","iso":"en-US","file":"en.ts"}],
  localeCodes: ["ja","en"],
}

export const localeMessages = {
  'ja.ts': () => Promise.resolve(locale77830c60),
  'en.ts': () => import('../../src/lang/en.ts' /* webpackChunkName: "lang-en.ts" */),
}
