import { Plugin } from '@nuxt/types';
import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from 'vee-validate'; // 使用する機能
import * as rules from 'vee-validate/dist/rules'; // ← 全てのデフォルトルールをインポートする
// safariとアプリでbehavior: 'smooth'が効かなかったのでporifillをインポート
import 'scroll-behavior-polyfill';
// import ja from 'vee-validate/dist/locale/ja.json'; // ← vue-i18nの方の多言語ファイルを使用するので使わない
// import en from 'vee-validate/dist/locale/en.json'; // ← vue-i18nの方の多言語ファイルを使用するので使わない
// import fr from 'vee-validate/dist/locale/fr.json'; // ← vue-i18nの方の多言語ファイルを使用するので使わない

// localize('ja', ja); // ← vue-i18nの方の多言語ファイルを使用するので使わない
// localize('en', en); // ← vue-i18nの方の多言語ファイルを使用するので使わない
// localize('fr', fr); // ← vue-i18nの方の多言語ファイルを使用するので使わない

const getPdfSize = (base64Data: string) => {
  /**
   * fileのdataが空の時がある（PDFを登録済みのポートフォリオの編集時等）
   * 空の時はファイルサイズを0で返す
   */
  if (!base64Data) {
    return 0;
  }
  // Base64データのプレフィックスを取り除く
  const base64String = base64Data.split(',')[1];

  // Base64データをバイナリに変換
  const binaryString = atob(base64String);
  const len = binaryString.length;

  // ファイルサイズをMBで計算
  const sizeInBytes = len;
  const sizeInMB = sizeInBytes / (1024 * 1024);

  return sizeInMB;
};

/**
 * プラグインそのもの
 * @param context (※使用しない場合は変数名を _ にすると警告回避)
 * @param inject
 */
// eslint-disable-next-line require-await
const veeValidatePlugin: Plugin = async (context, _inject) => {
  // vee-validateを利用するために必要なコンポーネントをグローバルに登録しておく
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);

  // インポートしてきたデフォルトルールを使用するために全て登録する
  for (const rule in rules) {
    // @ts-ignore
    // eslint-disable-next-line import/namespace
    extend(rule, rules[rule]);
  }

  // TODO: もしもvee-validateのデフォルトルールではアプリの要件を満たせない場合はここで新たなルールを定義する
  /**
   * 【ルール.01】パスワードの一致確認
   * 指定された他のフィールドの値と一致するかどうかを見ている
   */
  extend('match', {
    params: ['target'],
    // @ts-ignore
    validate(value, { target }) {
      return value === target;
    },
  });

  /**
   * 二つのフィールド間で、両方入力されている(or されていない)かを確認
   * 片方だけ入力はNG
   * 例：入社試験の開催日と時間帯
   */
  extend('check_both_are_selected', {
    params: ['target1', 'target2'],
    // @ts-ignore
    validate(_value, { target1, target2 }) {
      if ((target1 && target2) || (!target1 && !target2)) {
        return true;
      }
      return false;
    },
  });
  /**
   * 0もfalse判定するメソッド
   * vee-validateが用意するrequiredでは0はtrue扱いになるため
   */
  extend('required_0_is_false', {
    validate(value: number) {
      if (value && value > 0) {
        return true;
      }
      return false;
    },
  });

  /**
   * 全角カタカナ
   * (※但し、半角全角スペースは許可する)
   */
  extend('zen_kata_kana', {
    validate(value) {
      return /^[ァ-ヶー\u{20}\u{3000}]+$/u.test(value);
    },
  });

  /**
   * 全角ひらがな
   * (※但し、半角全角スペースは許可する)
   */
  extend('zen_hira_kana', {
    validate(value) {
      return /^[ぁ-ゖー\u{20}\u{3000}]+$/u.test(value);
    },
  });

  /**
   * 電話番号
   */
  extend('phone', {
    validate(value) {
      return /^\+?\d(\d|-|\u{20})*\d$/u.test(value);
    },
  });

  /**
   * オブジェクトがnullではない
   */
  extend('object_not_empty', {
    validate: (value) => {
      return Object.keys(value).length > 0;
    },
  });

  /**
   * URL
   *
   * 【ルール】
   * URLの最低限の形を成しているか(プロトコル://ホスト " . "トップレベルドメイン)
   * スキームが存在(先頭が文字から始まる。それ以降は文字と数字と"+" / "-" / "."が許可される)
   * ホストとトップレベルドメインが存在する。
   * 文字制限(RFC3986ルール)
   * スラッシュの連続はNG
   */
  extend('url', {
    validate(value) {
      const reg =
        /^[a-zA-Z]+[a-zA-Z0-9+-.]*:\/\/([-_!~*'()a-zA-Z0-9;?:@&=+$,%#]+[/]?)+\.([-_.!~*'()a-zA-Z0-9;?:@&=+$,%#][/]?)+$/;
      return reg.test(value);
    },
  });

  /**
   * 4バイト文字
   *
   */
  extend('four_byte_chars', {
    validate: (value) => {
      // 正規表現を使用して4バイト文字を検索
      const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
      return !regex.test(value);
    },
  });

  extend('pdf_size_5MB', {
    validate(value) {
      const sizeInMB = getPdfSize(value.data);
      return Number(sizeInMB.toFixed(2)) < 5;
    },
  });

  // TODO: このコメントアウト部分をコピペしてアプリ独自の複合ルール等を作成していく
  //       バリデーションエラー時のメッセージは assets/i18n/commons/ja.yml 等にルール名と一致させて追記していく
  //       ↓下の例なら assets/i18n/commons/ja.ymlに
  //          vee.fields.hogehoge: 'フィールドの名前'
  //          vee.validation.hogehoge: 'エラーが発生したときのメッセージ'
  //        といった感じで登録する
  // /**
  //  * 【ルール.xx】YYYとZZZの確認
  //  */
  // extend('hogehoge', {
  //   params: ['target'],
  //   // @ts-ignore
  //   validate(value, { target }) {
  //     return value === target;
  //   },
  // });

  // Vue-i18nの言語ファイルを流用してvee-validateのValidation時のerrorメッセージを設定する
  configure({
    // @ts-ignore
    defaultMessage: (field, values) => {
      // フィールド名を設定する「お名前」は必須項目ですとかの「お名前」の部分
      // @ts-ignore
      // values._field_ = context.app.i18n.t(`vee.fields.${field}`);
      values._field_ = field;

      // console.log('あいうえお');
      /// console.log(values.target);

      // 「match」ルール用のためのターゲットラベルの翻訳データを取得
      // values._target_label_ = context.app.i18n.t(`vee.fields.${values.target}`);
      values._target_label_ = values.target;

      // ルール毎のエラーメッセージ '{_field_}は{length}文字以内にしてください' とかそういうやつを設定
      // @ts-ignore
      return context.app.i18n.t(`vee.validation.${values._rule_}`, values);
    },

    // 検証状態に応じてcssを割り当てる設定
    classes: {
      untouched: ['-vee-untouched'],
      touched: ['-vee-touched'],
      dirty: ['-vee-dirty'],
      pristine: ['-vee-pristine'],
      valid: ['-vee-valid'],
      invalid: ['-vee-invalid'],
      validated: ['-vee-validated'],
      pending: ['-vee-pending'],
      required: ['-vee-required'],
      changed: ['-vee-changed'],
      passed: ['-vee-passed'],
      failed: ['-vee-failed'],
    },
  });

  // Nuxtの様々なコンテキストに対象のオブジェクトを生やすためDI関数をコールしておく
  // inject('???', ???); // ← 何かフレームワークにDIしときたいものがあれば登録しておくがvee-validateは特に無い
};
export function useValidate() {
  // バリデーションに引っかかったエラー箇所までスクロールする関数
  const scrollToErrorPoint = (
    observer: InstanceType<typeof ValidationObserver>
  ) => {
    // エラーを取得
    const errors = Object.entries(observer.errors)
      .map(([key, value]) => ({ key, value }))
      .filter((error) => error.value.length);
    // 0番目のエラー箇所までスクロール
    observer.refs[errors[0].key].$el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  return { scrollToErrorPoint };
}
export default veeValidatePlugin;
