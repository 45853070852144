import { MutationTree, ActionTree } from 'vuex';

export interface State {
  feedId: number | null;
  feedType: string;
  categoriesTubNo: number | null;
}

export const state: () => State = () => ({
  feedId: null,
  feedType: '',
  categoriesTubNo: 0,
});

export const mutations: MutationTree<State> = {
  feedIdMutation(state, id: number) {
    state.feedId = id;
  },
  feedTypeMutation(state, type: string) {
    state.feedType = type;
  },
  categoriesTubNoMutation(state, tubNo: number) {
    state.categoriesTubNo = tubNo;
  },
};

export const actions: ActionTree<State, any> = {
  updateFeedId({ commit }, id: number) {
    commit('feedIdMutation', id);
  },
  updateFeedType({ commit }, type: number) {
    commit('feedTypeMutation', type);
  },
  updateCategoriesTubNo({ commit }, tubNo: number) {
    commit('categoriesTubNoMutation', tubNo);
  },
};
