const middleware = {}

middleware['apollo-check'] = require('../src/middleware/apollo-check.ts')
middleware['apollo-check'] = middleware['apollo-check'].default || middleware['apollo-check']

middleware['authenticated'] = require('../src/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['dev-mode'] = require('../src/middleware/dev-mode.ts')
middleware['dev-mode'] = middleware['dev-mode'].default || middleware['dev-mode']

middleware['layout'] = require('../src/middleware/layout.ts')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['loading-random-number'] = require('../src/middleware/loading-random-number.ts')
middleware['loading-random-number'] = middleware['loading-random-number'].default || middleware['loading-random-number']

middleware['reverse-proxy'] = require('../src/middleware/reverse-proxy.ts')
middleware['reverse-proxy'] = middleware['reverse-proxy'].default || middleware['reverse-proxy']

middleware['role-redirect-proxy'] = require('../src/middleware/role-redirect-proxy.ts')
middleware['role-redirect-proxy'] = middleware['role-redirect-proxy'].default || middleware['role-redirect-proxy']

middleware['sentry-initialized'] = require('../src/middleware/sentry-initialized.ts')
middleware['sentry-initialized'] = middleware['sentry-initialized'].default || middleware['sentry-initialized']

export default middleware
