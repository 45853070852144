import uuidRandom from 'uuid-random';
import dayjs from 'dayjs';
import { Context } from '@nuxt/types';
import useCookie, { CookieKeys } from './useCookie';

interface UseUuidOptions {
  context?: Context;
}

/**
 * UUIDを管理する
 */
export function useUuid(options?: UseUuidOptions) {
  const { getCookie, setCookie } = useCookie({ context: options?.context });

  // UUID設定
  const resetUuid = () => {
    const uuid = uuidRandom();
    // Cookieを設定
    setCookie(CookieKeys.USER_UUID, uuid, {
      path: '/',
      expires: dayjs().add(50, 'year').toDate(),
    });
    return uuid;
  };

  return {
    uuid: getCookie(CookieKeys.USER_UUID) || resetUuid(),
    resetUuid,
  };
}
