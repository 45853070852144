import { FetchResult } from '@apollo/client/core';
import {
  reactive,
  watch,
  InjectionKey,
  onGlobalSetup,
} from '@nuxtjs/composition-api';
import {
  useNewNoticeSubscription,
  useUserNoticeQuery,
} from '~/graphql/graphqlOperations';
import { useAppStore } from '~/store/app';
import useFlutter from '~/compositions/useFlutter';

// TODO: paramはFetchResultのハズだが公開されなくなってる？？要調査
type with_argument = (param?: any) => void;
type none_argument = () => void;

// コールバック関数の参照を保存用
let unread_subscription_callback: with_argument | none_argument | undefined;
let param_ref;

const unread_subscription_listener = [Function];

export type UnreadStatus = {
  news: false; // お知らせ
  offer: false; // オファー
  message: false; // メッセージ
  notice_board: false; // 学校掲示板
};
export type UnreadStatusCallBack = (param: any) => void;

// type MyCallback = (name: type) => returntype;

/**
 * ユーザの未読情報をリアクティブに提供する関数
 * 使用すると、自動で購読を始めてサーバ側で状態変更があった場合は、
 * サーバpushで状態を受け取る事ができる。
 * また、購読中にコールバック関数を設定したければ利用時に設定してける
 *
 * const unread_status_notification = useUnread(()=>{
 *   // 何かやりたい処理
 * })
 */
export function useUnread(callback?: with_argument | none_argument) {
  // コールバック関数の参照を保存しておく
  unread_subscription_callback = callback;

  console.log('useUnread')

  // 購読にユーザのIDを利用するので取得しておく
  const { store } = useAppStore();

  // badgecount
  const { setAppBadgeCount, isFlutter } = useFlutter();

  // ユーザへの通知状態の格納用変数
  // それぞれの項目に関して【未読】の物があれば true になる
  const unread_status_notification = reactive({
    news: false, // お知らせ
    offer: false, // オファー
    message: false, // メッセージ
    notice_board: false, // 学校掲示板
  });

  // 【ユーザ:未読:情報:通知】【初回】【クエリ本体】
  const { onResult: userNoticeOnResult } = useUserNoticeQuery();

  // 【ユーザ:未読:情報:通知】【初回】【成功時】【イベントハンドラ】
  userNoticeOnResult((param) => {

    // バッジ数を減らす
    if (isFlutter) {
      if (store.user?.id) {
        const badgecount = param.data?.userNotice.badgeCount;
        if (typeof badgecount === 'number') {
          setAppBadgeCount(badgecount);
        }
      }
    }

    // 各項目を画面表示用のりアクティブ変数に格納
    unread_status_notification.news = param.data?.userNotice.news;
    unread_status_notification.offer = param.data?.userNotice.offer;
    unread_status_notification.message = param.data?.userNotice.message;
    unread_status_notification.notice_board =
      param.data?.userNotice.noticeBoard;
  });

  // 【ユーザ:未読:情報:通知】【購読】【検索条件】
  const useNewNoticeSubscriptionValiables = reactive({
    userId: -9999, // ログインしていない間は誰でもないので取り敢えず存在しないようなIDを与えておく
  });

  // 【ユーザ:未読:情報:通知】【購読】【実行条件】
  const useNewNoticeSubscriptionOptions = reactive({
    enabled: false, // ユーザがログインしていない可能性を考慮して最初は false で実行を抑制
  });

  // ログイン前はユーザが取得出来ないため、ユーザへの通知を受け取ること等出来ない。
  // ユーザのIDの状態を監視して、ユーザIDが取れたタイミングで【ユーザ通知】の【購読】を始める
  watch(
    () => store.user?.id,
    (now) => {
      if (now) {
        useNewNoticeSubscriptionValiables.userId = now; // 【ユーザ通知】【購読】のユーザIDに取得出来たユーザIDを設定
        useNewNoticeSubscriptionOptions.enabled = true; // 【ユーザ通知】【購読】を開始するために、開始フラグを立てる
      }
    }
  );

  // 【ユーザ:未読:情報:通知】【購読】【クエリ本体】
  const {
    // loading: newNoticeSubscriptionLoading,
    // result: newNoticeSubscriptionResult,
    // error: newNoticeSubscriptionError,
    onResult: newNoticeSubscriptionOnResult,
  } = useNewNoticeSubscription(
    useNewNoticeSubscriptionValiables,
    useNewNoticeSubscriptionOptions
  );

  // 【ユーザ:未読:情報:通知】【購読】【成功時】【イベントハンドラ】
  newNoticeSubscriptionOnResult((param) => {
    // console.warn('⭐⭐⭐ useUnread - newNoticeSubscriptionOnResult ⭐⭐⭐');
    // console.warn(param.data?.newNotice);

    // 各項目を画面表示用のりアクティブ変数に格納
    unread_status_notification.news = param.data?.newNotice?.news ?? false;
    unread_status_notification.offer = param.data?.newNotice?.offer ?? false;
    unread_status_notification.message =
      param.data?.newNotice?.message ?? false;
    unread_status_notification.notice_board =
      param.data?.newNotice?.noticeBoard ?? false;

    // console.warn('今の状態', unread_status_notification);

    // コールバック関数が定義されている場合はそれを実行する
    if (unread_subscription_callback) {
      unread_subscription_callback(param);
    }

    const param_ref = param;

    unread_subscription_listener.forEach((listener) => {
      // console.warn('👒👒👒👒 ふぉーいーち 👒👒👒👒');
      // console.warn(listener);

      // console.warn('param_ref', param_ref);

      // @ts-ignore
      // listener(refbuf);
      listener.call(param_ref);
      // @ts-ignore
      // listener(param);
      // @ts-ignore
      // listner(param);
    });
  });

  // callback = (func: Function) => {
  //   if (func) {
  //     func();
  //   }
  // };

  const addSubscriptionListener = (func: any) => {
    unread_subscription_listener.push(func);
  };

  return { unread_status_notification, addSubscriptionListener };
}
export const UnreadStatusKey: InjectionKey<UnreadStatus> =
  Symbol('UnreadStatus');

export const UnreadCallBackKey: InjectionKey<UnreadStatusCallBack> = Symbol(
  'UnreadStatusCallBack'
);

// export const UnreadStatus;
