// import Vue from 'vue';
// import VueAwesomeSwiper from 'vue-awesome-swiper';

// // import style
import 'swiper/swiper-bundle.css';

// Vue.use(VueAwesomeSwiper /* { default options with global component } */);

import Vue from 'vue';
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  History,
} from 'swiper';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Mousewheel, Autoplay, History]);

Vue.use(getAwesomeSwiper(SwiperClass));
