import { nextTick, onUnmounted, onMounted } from '@nuxtjs/composition-api';
export function useWindowResize() {
  let start_time: number | null = null;

  const resize = function () {
    nextTick(() => {
      // 処理開始の時間が入っていなければ設定する
      if (!start_time) {
        start_time = window.performance.now();
        // console.warn('start_timeがnullだったのでだったのでセットした');
      }

      // 基本レイアウトのHTMLノードを取得する
      const base_container_node: HTMLDivElement | null = document.querySelector(
        '#base-container'
      );

      // アニメーションを開始しフレームIDをを取得する
      const animatin_frame_id = window.requestAnimationFrame(resize);

      // 【基本レイアウトのコンテナ区画の高さ】と【OSのメニューバー(※URLアドレスバー等)を除いた画面の高さの幅】を比較する
      if (base_container_node?.style?.height === `${window.innerHeight}px`) {
        // console.warn(`start【${start_time}】 end【${window.performance.now()}】`);
        // console.warn(`差分=${window.performance.now() - start_time}`);

        // 300㍉秒同じ状態が続いたらリサイズ処理が終わっていると見做す。
        if (window.performance.now() - start_time > 500) {
          start_time = null;
          // console.warn('差分が500を超えたのでアニメーションを停止');
          // アニメーションを停止する
          window.cancelAnimationFrame(animatin_frame_id);
        }
      } else {
        // 基本レイアウトのコンテナの高さを調整する
        base_container_node
          ? (base_container_node.style.height = `${window.innerHeight}px`)
          : console.warn('base_container要素が取得できなかった');

        // html要素を取得して高さを調整する
        const html_node: HTMLHtmlElement | null = document.querySelector(
          'html'
        );
        html_node
          ? (html_node.style.height = `${window.innerHeight}px`)
          : console.warn('html要素が取得できなかった');

        // body要素も高さを調整する
        document.body.style.height = `${window.innerHeight}px`;
      }
    });
  };

  onMounted(() => {
    // SSRのためにクライアントの場合だけ処理するようにしておく
    if (process.client) {
      // 初期表示のために最初は一回呼んでおく
      resize();
      // 画面のリサイズイベントリスナーに登録しておく
      window.addEventListener('resize', resize, { passive: true });
    }
  });

  onUnmounted(() => {
    // console.warn('onUnmounted() resize listener remove');
    // SSRのためにクライアントの場合だけ処理するようにしておく
    if (process.client) {
      // 画面のリサイズイベントリスナーを外しておく
      window.removeEventListener('resize', resize);
    }
  });

  return {};
}
