import { onGlobalSetup, provide } from '@nuxtjs/composition-api';
import { Plugin } from '@nuxt/types';
import {
  useUnread,
  UnreadStatusKey,
  UnreadCallBackKey,
} from '~/compositions/useUnread';

/**
 * システム全体のグローバルなスコープに【ユーザ未読情報】のサブスクライブ機能を提供する
 */
const ApolloPlugin: Plugin = ({ app }) => {
  onGlobalSetup(() => {
    // console.warn('🌸🌸🌸 provide_subscription_unread 🌸🌸🌸');
    const unread = useUnread();
    // console.warn('unread', unread);
    // provide(UnreadStatusKey, useUnread());
    // @ts-ignore
    provide(UnreadStatusKey, unread.unread_status_notification);
    provide(UnreadCallBackKey, unread.addSubscriptionListener);
  });
};
export default ApolloPlugin;
