import Vue from 'vue';

/**
 * v-visible=[boolean型] というカスタム属性を追加
 * style で言う所の visibility: hidden の操作
 * (※使うかどうかはともかく、サンプル的なコード)
 */
Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});
