//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    iconName: {
      type: String,
      default: '',
    },
    iconTitle: {
      type: String,
      default: '',
    },
    iconDescription: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
