import Vue from 'vue';
// // import VModal from 'vue-js-modal/dist/ssr.index';
// // @ts-ignore
// import VModal from 'vue-js-modal/dist/ssr.index';
// // import VModal from 'vue-js-modal';

// @ts-ignore
// import VModal from 'vue-js-modal/dist/ssr.nocss.js';
// import 'vue-js-modal/dist/styles.css';

import Sticky from 'vue-sticky-directive';
Vue.use(Sticky);
