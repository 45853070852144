import { Plugin } from '@nuxt/types';

/**
 * ページで使用する。
 * routeの遷移に対して、前のページが正規表現のパスだったかどうか判定して
 * true falseを返す
 */
type FuncIsFrom = (regex: RegExp) => boolean;

// 以下、いろいろな場所に型を生やす
declare module 'vue/types/vue' {
  interface Vue {
    $is_from: FuncIsFrom;
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $is_from: FuncIsFrom;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $is_from: FuncIsFrom;
  }
}

/**
 * Nuxtにシステム共通のエラー処理が出来る関数を用意するプラグイン
 * 呼び出すと、Sentryに対してエラーログが送信されると共に、
 * Nuxtのフレームワークのエラー処理が実行される
 * 使用するときは、ComponentやPageの側で、
 * return context.root.$error({})といった形で呼び出す。
 * (※必ず return しながら使わないと、下の処理に流れてしまうので注意)
 */
const plugin: Plugin = (context, inject) => {
  const state = { to: null, from: null };

  //  context.

  // console.log(context.app.router);

  // context.app.router?.afterEach((to: any, from: any) => {
  //   // 遷移してきたときに、「募集情報」の一覧画面かどうか判定する
  //   // nuxt-i18n多言語化ライブラリがURLのプレフィクスとしてつける
  //   // 「/en/」 のようなロケール文字列がURLに含まれている場合は取り除いて判定する
  //   // if (to.path.replace(/^\/[a-zA-Z]{2}\//, '/') === URLs.show_recruit_info) {
  //   //   console.log();
  //   //   console.log(from);
  //   // }
  //   // if (to.path.)
  //   // console.log('だしてみるで');
  //   // console.log(from);
  //   state.to = to;
  //   state.from = from;
  // });

  // 関数をその場で実体化しながらインジェクトする
  inject('is_from', (regex: RegExp) => {
    // if (process.server) {
    //   context.
    // }
    // console.log(context.from);

    return !!context.from.path.match(regex);

    // return regex.exec(context.from.path);
    // console.log(state.from);
    // ログ蓄積のWebサービス(Sentry)にエラーを送信しておく
    // context.$sentry.captureException(new Error(nuxterror.message));
    // // Nuxtフレワームワークの共通エラー処理を呼んでおく
    // context.error(nuxterror);

    // return true;
  });
};
export default plugin;
