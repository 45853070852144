import {
  inject,
  onGlobalSetup,
  provide,
  reactive,
  useContext,
  watchEffect,
} from '@nuxtjs/composition-api';
import { ApolloClients, DefaultApolloClient } from '@vue/apollo-composable';
import { Plugin } from '@nuxt/types';
import { InMemoryCache } from '@apollo/client/cache';
import {
  getMainDefinition,
  offsetLimitPagination,
  relayStylePagination,
} from '@apollo/client/utilities';
import { ApolloClient, createHttpLink, split } from '@apollo/client/core';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { useAppStore } from '~/store/app';
import { useUuid } from '~/compositions/useUuid';
import { Credential } from '~/graphql/graphqlDeviseOperations';

import { APIHost } from '~/plugins/global-setup/provide_enviroment_hosts';
const ApolloPlugin: Plugin = ({ app }) => {
  onGlobalSetup(() => {
    // console.warn('⭐⭐⭐ provide_apollo_client.ts ⭐⭐⭐');
    // console.warn('inject(APIHost)', inject(APIHost));
    // provide(DefaultApolloClient, app.apolloProvider.defaultClient);

    const headers = reactive<{ [key: string]: any }>({});

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            jobs: offsetLimitPagination(['conditions', ['corporationId']]),
            internships: offsetLimitPagination([
              'conditions',
              ['corporationId'],
            ]),
            seminars: offsetLimitPagination(['conditions', ['corporationId']]),
            feeds: offsetLimitPagination([
              'conditions',
              ['corporationId', 'categoryId', 'sortType'],
            ]),
            paginationMessages: relayStylePagination(['roomId']),
            // feeds: offsetLimitPagination((context) => {
            //   // console.warn('コンテキスト出してみる');
            //   // console.warn(context);
            //   return !context?.conditions.categoryId
            //     ? ['conditions', ['sortType']]
            //     : ['conditions', ['categoryId', 'sortType']];
            // }),
          },
        },

        SocialLink: {
          keyFields: ['provider'],
        },
      },
    });

    const httpLinkOptions = reactive({
      // You should use an absolute URL here
      uri: `${inject(APIHost)}/graphql`,
      credentials: 'include', // withCredentials = true 設定
      headers,
      fetch,
    });
    const httpLink = createHttpLink(httpLinkOptions);
    // const clientOptions = reactive<ApolloClientOptions>({
    //   ssrMode: true,
    //   link: httpLink,
    //   cache,
    //   defaultOptions: {
    //     watchQuery: {
    //       fetchPolicy: 'cache-and-network',
    //     },
    //   },
    // });

    // `${context.root.$config.API_HOST || process.env.API_HOST}/graphql`

    // ローカルで動いているAPIに向けるとき
    // const cable = createConsumer('ws://localhost:3000/cable');

    // stg-railsで動いているAPIに向けるとき
    const cable = createConsumer(`${inject(APIHost)}/cable`);

    // const channelName = ''
    const wsLink = new ActionCableLink({ cable });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const apolloClient = new ApolloClient({
      ssrMode: true,
      link: splitLink,
      cache,
      ssrForceFetchDelay: 100,
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          // fetchPolicy: 'cache-first',
        },
      },
    });
    provide(DefaultApolloClient, apolloClient);

    // const clientAuthOptions = reactive({
    //   uri: `${
    //     context.root.$config.API_HOST || process.env.API_HOST
    //   }/graphql_auth`,
    // });
    const httpAuthLink = createHttpLink({
      // You should use an absolute URL here
      uri: `${inject(APIHost)}/graphql_auth`,
      credentials: 'include', // withCredentials = true 設定
      headers,
      fetch,
    });
    const apolloDeviseClient = new ApolloClient({
      ssrMode: true,
      cache,
      link: httpAuthLink,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          // fetchPolicy: 'cache-first',
        },
      },
    });
    provide(ApolloClients, {
      default: apolloClient,
      auth: apolloDeviseClient,
    });

    const { store } = useAppStore();
    const { uuid } = useUuid();
    const setHeader = (credentials?: Credential | null) => {
      if (credentials) {
        headers['access-token'] = credentials.accessToken;
        headers['token-type'] = credentials.tokenType;
        headers.client = credentials.client;
        headers.expiry = credentials.expiry;
        headers.uid = credentials.uid;
      } else {
        delete headers['access-token'];
        delete headers['token-type'];
        delete headers.client;
        delete headers.expiry;
        delete headers.uid;
      }
      headers.uuid = uuid;
    };
    setHeader(store.credentials);
    watchEffect(() => {
      setHeader(store.credentials);
    });
  });
};
export default ApolloPlugin;
