/**
 * 文言：キャリアマップについて & 就職活動お役立ち情報
 */
const loadingTextList = [
  // キャリアマップについて
  'キャリアマップは登録人数約140,000人！たくさんの人が使用している就活専門アプリだよ。',
  'キャリアマップは専門、技術職の採用に特化した業界最大級のサービス！たくさん活用してね♪',
  '学生・卒業生、学校、そして企業をつなぐキャリアマップ！',
  'キャリアマップは学内の就職指導システムとしても活用されているよ。',
  'キャリアマップを活用する学校は毎年増えているよ♪',
  'リスの「キャッチくん」と「キャリーちゃん」はキャリアマップの公式キャラクター。\nこれからもよろしくね♪',
  'キャリアマップの公式キャラクター「キャッチくん」と「キャリーちゃん」、実は双子だったの、知ってた？',
  'キャッチくんの将来の夢はお菓子職人になることなんだ。\n甘いものが大好物で、色んなお菓子を作れるように勉強中。',
  'キャリーちゃんの将来の夢は、施工管理士！清水寺の大きさにびっくり感動して、施工管理を目指すようになったんだよ。',
  // 就職活動お役立ち情報
  '履歴書登録の完成度が高ければ高いほど、オファーがもらえる確率が上がるよ！まずは75%の完成度を目指そう！',
  'ポートフォリオはもう登録した？アピールしたい作品や活動など、テキストだけでは伝えきれない自己PRはポートフォリオを活用しよう！',
  '「特別授業」にはたくさんの企業による、ためになる記事がいっぱい！こまめに確認して目指す業界の情報をゲットしよう！',
  '「特別授業」の記事を読んで、気になる企業をチェックしてみよう！',
  '就活ガイダンスでしっかりスタートを切れるように、ガイダンスには前向きに、積極的に参加しよう！',
  '就活ガイダンスが始まる前までに、ポートフォリオを完成させているとベスト！',
  '就活を始める前に、業界分析、企業分析、自己分析を行って、準備万端！',
  '興味のある業界をもっとよく知るには、今注目されている企業のことをネットなどで調べてみるといいかも。',
  '今学校で学んでいることや、興味のあることをベースに、より自分が活躍できる分野を見つけてみよう！',
  '企業分析は、気になる会社のホームページを見て、事業内容や働いている先輩の雰囲気を見るのがおすすめ！',
  '企業分析は、気になる会社の経営理念を確認し、会社の方向性や考え方を自分の価値観と重ねてみるといいかも。',
  '自己分析は自分一人で行うのではなく、家族や友人、先生に相談しながら進めてみよう。',
  '自己分析をして、好きなこと・得意なことややりたいこと・需要があること、の3つが重なる仕事を探してみよう！',
  'インターンシップに行けるチャンスがあったらぜひ行ってみて！業界のことがもっとよく知れるいい機会だよ。',
  '就活は「勝ち負け」じゃない！ご縁のあった企業に入ってみて、自分に合っていたら最高！',
  '就活は「いかに準備したか」が重要だよ！だからこそ早めに準備を始めようね。',
  '就活ノートを一冊作ってみよう！自分の活動の記録をつけたり見返したりすることで、自分の自信にもつながるよ。',
  '就活は情報戦！キャリアマップで業界研究や企業研究を進めよう。',
  '自分の長所がわからない時は、身近な人に聞いてみよう！',
  '自分の弱点だと思うポイントも、他の人から見たら強みに見えることだってあるよ！',
  '自己PRは過去のエピソードを交えて書くと、より具体的になるよ！',
  '自己PRは自慢にならないよう、自分の人柄を上手に伝えるのが大事だよ！',
  '「先輩の活躍」では、卒業生や同じ業界の先輩が働いている姿をチェックすることができるよ。\n素敵！と思ったらいいねしてみてね♪',
  '気になる募集情報はブックマークしておこう！あとでマイページからチェックできるようになるよ。',
  '志望条件はもう設定した？希望する条件を登録しておくと、気になる企業からオファーがもらえるチャンスもUPするよ！',
];

/**
 * 文言：季節ごとのお役立ち情報
 */
// 3月、7月、8月、12月（長期休暇前、長期休暇時）
const everySeasonalList01 = [
  '長期休暇中は就活準備にもってこい！バイトも就活もがんばって♪',
  '今日もキャリアマップを開いてくれてありがとう！',
  '長期休暇中にインターンシップに参加してみるのもオススメだよ。\nこの期間にお仕事のこと、もっとよく知れたらいいよね♪',
];

// 1月〜3月
const everySeasonalList02 = [
  '会社説明会は毎年3月くらいから始まることが多いよ。\n最終学年を迎える人はもうそろそろ就活準備スタートだね！',
  '色々な企業を調べて、実際に見学して、どんな職場が自分に合っているか研究してみよう！',
];

// 4月
const everySeasonalList03 = [
  '新生活には慣れたかな。少し散歩でもしてみない？',
  '理想の睡眠時間は7~8時間なんだって。休息はしっかり取ってね！',
  '4月だね！桜餅を食べてパワーチャージしよう♪',
];

// 5月、6月
const everySeasonalList04 = [
  '日中の天気が不安定な日は折り畳み傘を持ち歩こう！',
  '汗ばむ日も増えてきたね。ハンカチを忘れずに！',
];

// 7月、8月
const everySeasonalList05 = [
  '暑さで疲れてない？こまめな水分・塩分補給を忘れずにね。',
  '水ようかんがおいしい季節だね！就活の息抜きも大切にね♪',
];

// 9月、10月
const everySeasonalList06 = [
  '就活って頭を使うよね！栗きんとん食べてひと休みひと休み♪',
  '紅葉が綺麗な季節になってくるね。\n就活は大変だけど、その合間に美しい季節をぜひ楽しんでね！',
];

// 11月、12月、1月、2月
const everySeasonalList07 = [
  'そろそろ気温が下がってくるよ。\n寒さ対策はバッチリかな？体調には充分に気を付けてね！',
  '会社説明会や企業実習、面接などの訪問時は、コートやマフラーは建物に入る前に脱ぐのがマナーだよ。',
];

export default function useLoadingText() {
  // 今日の日付を取得
  const today = new Date();

  // 今日の月を取得 (0 から始まるため、1 を加えて実際の月に変換)
  const currentMonth = today.getMonth() + 1;

  if (
    currentMonth === 3 ||
    currentMonth === 7 ||
    currentMonth === 8 ||
    currentMonth === 12
  ) {
    loadingTextList.push(...everySeasonalList01);
  }

  if (currentMonth === 1 || currentMonth === 2 || currentMonth === 3) {
    loadingTextList.push(...everySeasonalList02);
  }

  if (currentMonth === 4) {
    loadingTextList.push(...everySeasonalList03);
  }

  if (currentMonth === 5 || currentMonth === 6) {
    loadingTextList.push(...everySeasonalList04);
  }

  if (currentMonth === 7 || currentMonth === 8) {
    loadingTextList.push(...everySeasonalList05);
  }

  if (currentMonth === 9 || currentMonth === 10) {
    loadingTextList.push(...everySeasonalList06);
  }

  if (
    currentMonth === 11 ||
    currentMonth === 12 ||
    currentMonth === 1 ||
    currentMonth === 2
  ) {
    loadingTextList.push(...everySeasonalList07);
  }

  return { currentMonth, loadingTextList };
}
