/**
 * システム全体で利用されるような様々ユーティリティライブラリをまとめ上げたモジュール
 * 現在システム全域で利用されている context.root.○○○ といったアクセス方法はいずれ無くなる可能性がある。
 * (※ context.root ← この部分の root へのアクセスが deprecated )
 * そのため、Tree Shaking も考慮した状態で合成関数方式での各モジュールへのアクセスを提供する。
 * 例えば、 Vueルーターへアクセスしたければ、
 *
 *   import { useAppRouter } from '~/compositions/useUtils';
 *
 *   const router = useAppRouter();
 *
 * の様に限定的にアクセスして利用する。
 * (※ この時 Tree Shaking を効かせるために、利用しない他のモジュールはインポートしないこと！！)
 */
import { useContext } from '@nuxtjs/composition-api';
// export function useUtils() {
//   const {
//     $config,
//     route: $route,
//     // @ts-ignore
//     // buefy: $buefy,
//     app: {
//       $cookie,
//       $cookies,
//       $dayjs,
//       $error,
//       $ua,
//       i18n: $i18n,
//       $sentry,
//       router: $router,
//       localePath: $localePath,
//       switchLocalePath: $switchLocalePath,
//     },
//   } = useContext();
//   return {
//     $config,
//     $route,
//     // $buefy,
//     $cookie,
//     $cookies,
//     $dayjs,
//     $error,
//     $ua,
//     $i18n,
//     $sentry,
//     $router,
//     $localePath,
//     $switchLocalePath,
//   };
// }
export function useSsrContext() {
  return useContext().ssrContext;
}
export function useConfig() {
  return useContext().$config;
}
// export function useAppRoute() {
//   return useContext().route.value;
// }
export function useQuery() {
  return useContext().query;
}
export function useParams() {
  return useContext().params;
}
export function useCookie() {
  return useContext().app.$cookie;
}
export function useCookies() {
  return useContext().app.$cookies;
}
export function useDayjs() {
  return useContext().app.$dayjs;
}
export function useError() {
  return useContext().app.$error;
}
export function useUserAgent() {
  return useContext().app.$ua;
}
export function useI18n() {
  return useContext().app.i18n;
}
export function useSentry() {
  return useContext().app.$sentry;
}
// export function useAppRouter() {
//   return useContext().app.router;
// }
export function useLocalePath() {
  return useContext().app.localePath;
}
export function useSwitchLocalePath() {
  return useContext().app.switchLocalePath;
}
export function useGoogleAnalytics() {
  return useContext().app.$ga;
}
export function useMQ() {
  return useContext().app.$mq;
}
